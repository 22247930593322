<template style="">
    <div class="table-box">
        {{propsToData}}
        <table class="table-historico">
            <thead>
                <tr>
                    <th v-on:click="setAsc(1)">
                        Retails
                        <i
                            class="pi pi-sort-amount-up-alt"
                            :style="'font-size: 1rem; color: ' + asc_color1"
                        ></i>
                    </th>
                    <th>Categorias</th>
                    <th>Producto</th>
                    <th v-on:click="setAsc(2)">
                        Brands
                        <i
                            class="pi pi-sort-amount-up-alt"
                            :style="'font-size: 1rem; color: ' + asc_color2"
                        ></i>
                    </th>
                    <th>Feature 1</th>
                    <th>Feature 2</th>
                    <th>Feature 3</th>
                    <th>Feature 4</th>
                    <th>Feature 5</th>
                    <th>Precio Lista</th>
                    <th>Precio Oferta</th>
                    <th>Precio Extra</th>
                    <th>Promo</th>
                    <th>Posición</th>
                    <th>Fecha</th>
                    <th>Enlace</th>
                    <th>Stock</th>
                    <th>Sku</th>
                    <th>Unidad</th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="item in returnAsc(values)"
                    :key="item.taxo_id"
                    :class="rowClass(item)"
                >
                    <td>
                        <Chip :label="item.retail" class="chip-custom"></Chip>
                    </td>
                    <td>
                        {{ item.categoria }}
                    </td>
                    <td>
                        {{ item.producto }}
                    </td>
                    <td>
                        <Chip :label="item.brand" class="chip-custom"></Chip>
                    </td>
                    <td>
                        {{ item.feature_1 }}
                    </td>
                    <td>
                        {{ item.feature_2 }}
                    </td>
                    <td>
                        {{ item.feature_3 }}
                    </td>
                    <td>
                        {{ item.feature_4 }}
                    </td>
                    <td>
                        {{ item.feature_5 }}
                    </td>
                    <td>
                        <div v-if="item.precio_de_lista != 0">
                            {{ item.precio_de_lista }}
                        </div>
                        <div v-else>0,00</div>
                    </td>
                    <td>
                        <div v-if="item.precio_oferta != 0">
                            {{ item.precio_oferta }}
                        </div>
                        <div v-else>0,00</div>
                    </td>
                    <td>
                        <div v-if="item.precio_extra != 0">
                            {{ item.precio_extra }}
                        </div>
                        <div v-else>0,00</div>
                    </td>
                    <td>
                        <div v-if="item.promo != 0">
                            {{ item.promo }}
                        </div>
                        <div v-else>0,00</div>
                    </td>
                    <td>
                        {{ item.posicion }}
                    </td>
                    <td>
                        {{ item.fecha }}
                    </td>
                    <td>
                        <a :href="item.enlace" target="_blank" class="link"
                            >Ver en sitio</a
                        >
                    </td>
                    <td>
                        {{ item.stock }}
                    </td>
                    <td>
                        {{ item.sku }}
                    </td>
                    <td>
                        {{ item.unidad }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
//Components primevue
import DataTable from "primevue/datatable";
import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputtext";
import Column from "primevue/column";
import MultiSelect from "primevue/multiselect";
import Chip from "primevue/chip";
import Toolbar from "primevue/toolbar";
import Button from "primevue/button";
import Checkbox from "primevue/checkbox";
import SplitButton from "primevue/splitbutton";
import ToggleButton from "primevue/togglebutton";

import AutoComplete from "primevue/autocomplete";

//Services
import { ProductsService } from "../../services/Taxonomia/ProductsService";
import { CategoriaServiceABM } from "../../services/CategoriaService";
import { FeatureService } from "../../services/FeatureService";
import { BrandService } from "../../services/BrandService";
import UsersServices from "../../services/UsersServices";

export default {
    components: {
        DataTable,
        Dropdown,
        InputText,
        Column,
        MultiSelect,
        Chip,
        Toolbar,
        Button,
        Checkbox,
        SplitButton,
        ToggleButton,
        AutoComplete,
    },

    props: ["data", "collapse", "options"],

    data() {
        return {
            asc1: "retail",
            asc_color1: "#2196f3",

            asc2: "brand",
            asc_color2: "#2196f3",

            values: [],
        };
    },

    computed: {
        propsToData() {
            this.values = this.data;
        }
    },

    watch: {
        asc1: function (newVal, olvVal) {
            this.asc_color1 = newVal !== "retail" ? "gray" : "#2196f3";
        },

        asc2: function (newVal, olvVal) {
            this.asc_color2 = newVal == "" ? "gray" : "#2196f3";
        },
    },

    methods: {
        setAsc(asc) {
            this.values = this.returnLineaColor(this.values);

            if (asc == 1) {
                this["asc" + asc] = this["asc" + asc] == "retail" ? "" : "retail";
                return;
            }
            if (asc == 2) {
                this.asc1 = this.asc1 == "" ? "brand" : this.asc1;
                this["asc" + asc] = this["asc" + asc] == "brand" ? "" : "brand";
                return;
            }

            return;
        },

        checkOptions(option) {
            if (this.options.length == 0) {
                return true;
            } else {
                var optionSelect = this.options.filter(
                    (item) => item == option
                );

                if (optionSelect.length == 0) {
                    return false;
                } else {
                    return true;
                }
            }
        },

        rowClass(data) {
            return data.class;
        },

        returnLineaColor(values) {
            let id = 0;

            let arr = values.map((item) => {
                console.log(item);
                id++;
                item.class = id % 2 === 0 ? "linea_gris" : "linea_white";

                return item;
            })

            return arr;
        },

        returnValueIndex(values, az, prop) {
            let propIndex = [];
            values.map((item) => {
                propIndex.push(item[prop]);
            });

            propIndex = propIndex.filter(
                (ele, pos) => propIndex.indexOf(ele) == pos
            );

            return az == true ? (propIndex = propIndex.sort()) : propIndex;
        },

        ascFunc(values, prop) {
            let grupos = [];
            let propIndex = this.returnValueIndex(values, true, prop);

            values.reduce((acumulador, current) => {
                if (!acumulador[prop]) {
                    acumulador = current;
                }

                if (acumulador[prop] == current[prop]) {
                    if (!grupos[acumulador[prop]])
                        grupos[acumulador[prop]] = [];
                    grupos[acumulador[prop]].push(current);
                } else {
                    if (!grupos[current[prop]]) grupos[current[prop]] = [];
                    grupos[current[prop]].push(current);
                }

                return (acumulador = current);
            }, {});

            return { grupos, propIndex };
        },

        returnAsc(values) {
            if (this.asc1) {
                let ascFunc = this.ascFunc(values, this.asc1);
                let valuesAsc = [];
                let grupos = ascFunc.grupos;

                let propIndex = ascFunc.propIndex;

                if (this.asc2.length > 0) {
                    console.log(this.asc2);
                    let valuesAsc2 = [];
                    let id = 0;
                    propIndex.map((index) => {
                        let ascFunc2 = this.ascFunc(grupos[index], this.asc2);

                        let grupos2 = ascFunc2.grupos;

                        let propIndex2 = ascFunc2.propIndex;

                        propIndex2.map((index) => {
                            grupos2[index].map((item) => {
                                id++;
                                item.class =
                                    id % 2 === 0 ? "linea_gris" : "linea_white";
                            });

                            valuesAsc2.push(...grupos2[index]);
                        });
                    });

                    values = valuesAsc2;
                    return values;
                }
                let id = 0;
                propIndex.map((index) => {
                    grupos[index].map((item) => {
                        id++;
                        item.class =
                            id % 2 === 0 ? "linea_gris" : "linea_white";
                    });

                    valuesAsc.push(...grupos[index]);
                });

                values = valuesAsc;
            }

            return values;
        },
    },
};
</script>