<template>
    <div>
        <div class="container-flex">
            <!-- @_____ == function callback -->
            <Query
                @getObtener="getObtener"
                :btnLeftLabel="'Buscar'"
                @click_export="exportExcel()"
                :type="'comparativo'"
                @limpiar_tabla="limpiar_tabla()"
                :disabledExport="productService.length > 0"
            ></Query>
            <transition name="fade">
                <SkuComponent v-if="total_dados > 0" :sku="total_dados"></SkuComponent>
            </transition>
        </div>

        <!-- @_____ == function callback -->
        <div v-if="productService.length > 0">
            <Table :data="productService" :price="price" :pricesRetails="pricesRetails"></Table>
        </div>

        <div class="container-custom" v-if="productService.length <= 0 && loading == true">
            <ProgressSpinner />
        </div>

        <div v-if="productService.length > 0">
            <Paginator :total_pages="total_pages" :total_dados="total_dados" :page_init="current_page"
                @handler="handlerPage"></Paginator>
        </div>
    </div>
</template>
<script>
import Dropdown from "primevue/dropdown";
import MultiSelect from "primevue/multiselect";
import Button from "primevue/button";
import Query from "./../components/Query/QueryCompHisto.vue";
import Table from "./../components/Tables/TableComparativa.vue";
import ProgressSpinner from "primevue/progressspinner";
import SkuComponent from "./../components/SkuComponent.vue"
import Paginator from "./../components/Paginator.vue";

import XLSX from "xlsx";
import file_saver from "file-saver/src/FileSaver.js";

import { ProductsService } from "./../services/Taxonomia/ProductsService";

export default {
    components: {
        Dropdown,
        MultiSelect,
        Button,
        Table,
        Query,
        ProgressSpinner,
        Paginator,
        SkuComponent
    },
    data() {
        return {
            // DATA - API
            productService: [],
            loading: false,

            //Alert
            time: 2000,

            total_dados: 0,
            current_page: 1,
            total_pages: null,

            this_props: null,
            price: null,
            request: false,

            loading: false,
        };
    },

    methods: {
        limpiar_tabla() {
            this.productService = [];
            this.loading = false;
            this.total_dados = 0;
            this.current_page = 1;
            this.total_pages = null;

            this.this_props = null;
            this.price = null;
            this.request = false;

            this.loading = false;
        },

        loadings(boolean) {
            if (boolean == true) {
                this.collapse = "table-collapsed"; //Css propriedades
                this.spinner = "spinner-loading"; //Css propriedades
            } else {
                setTimeout(() => {
                    this.collapse = "not-collapsed"; //Css propriedades
                    this.spinner = "spinner-not"; //Css propriedades
                }, 1500);
            }
        },

        getObtener(value) {
            this.productService = [];

            if (!value.country) {
                this.$store.dispatch("alert_error", {
                    //Alert
                    message: "Seleccione el país primero",
                    time: this.time, //Padrao 2000
                });
            } else if (!value.retail) {
                this.$store.dispatch("alert_error", {
                    //Alert
                    message: "Seleccione las retail primero",
                    time: this.time, //Padrao 2000
                });
            } else if (!value.category_abm) {
                this.$store.dispatch("alert_error", {
                    //Alert
                    message: "Seleccione las categoría primero",
                    time: this.time, //Padrao 2000
                });
            } else if (!value.price) {
                this.$store.dispatch("alert_error", {
                    //Alert
                    message: "Seleccione un tipo de precio",
                    time: this.time, //Padrao 2000
                });
            } else {
                this.price = value.price;

                var date = value.date;
                date = date.toLocaleDateString('pt-BR');
                date = date.split("/");
                date = date.reverse();
                date = date[0] + '-' + date[1] + '-' + date[2]

                var country = value.country;

                if (value.retail != null) {
                    var retails = value.retail.map((item) => {
                        return item.retail;
                    });
                } else {
                    var retails = [];
                }

                var categories = value.category_abm.id;

                if (value.brand != null) {
                    var brands = value.brand.map((item) => {
                        return item.id;
                    });
                } else {
                    var brands = [];
                }

                var obj = {
                    date: date,
                    country: country,
                    retails: retails,
                    categories: categories,
                    brands: brands,
                    page: this.current_page,
                };

                this.total_dados = 0;
                this.current_page = 1;
                this.total_pages = 0;

                this.this_props = obj;
                this.loading = true;

                this.loading = true;

                ProductsService.comparative(obj, true)
                    .then((result) => {
                        const resultValidation = this.$root.resultValidationRequest(result);

                        if (resultValidation != true) {
                            this.$store.dispatch("alert_error", {
                                message: resultValidation,
                                time: this.time,
                            });

                            return;
                        }
                        this.total_pages = result.data.pages;
                        this.total_dados = result.data.count;

                        if (result.data.count > 0) {
                            ProductsService.comparative(obj)
                                .then((result) => {
                                    const resultValidation = this.$root.resultValidationRequest(result);

                                    if (resultValidation != true) {
                                        this.$store.dispatch("alert_error", {
                                            message: resultValidation,
                                            time: this.time,
                                        });

                                        return;
                                    }
                                    var produtos = [];
                                    var varejos = [];
                                    var id = 0;
                                    result.data.data.map((item) => {
                                        id++;
                                        item.class =
                                            id % 2 === 0
                                                ? "linea_gris"
                                                : "linea_white"; // VERIFICA SE É PAR OU IMPAR
                                        produtos.push(item);

                                        item.products.map((item) => {
                                            if (this.price.id) {
                                                varejos.push(item.retail);
                                            }
                                        });
                                    });

                                    this.pricesRetails = varejos;
                                    this.productService = produtos;

                                    this.loading = false;
                                })
                                .catch((err) => { });
                        } else {
                            this.loading = false;
                            this.$store.dispatch("alert_error", {
                                message: "No encontramos datos",
                                time: 2000,
                            });
                        }
                    })
                    .catch((err) => { });
            }
        },

        handlerPage(page) {
            this.current_page = page;
            this.this_props.page = page;
            this.productService = [];
            console.log(this.productService);

            this.loading = true; //Css propriedades

            ProductsService.comparative(this.this_props)
                .then((result) => {
                    const resultValidation = this.$root.resultValidationRequest(result);

                    if (resultValidation != true) {
                        this.$store.dispatch("alert_error", {
                            message: resultValidation,
                            time: 5000,
                        });

                        return;
                    }
                    var produtos = [];
                    var varejos = [];
                    var id = 0;
                    result.data.data.map((item) => {
                        id++;
                        item.class =
                            id % 2 === 0 ? "linea_gris" : "linea_white"; // VERIFICA SE É PAR OU IMPAR
                        produtos.push(item);

                        item.products.map((item) => {
                            if (this.price.id) {
                                varejos.push(item.retail);
                            };
                        });
                    });

                    this.pricesRetails = varejos;

                    this.productService = produtos;

                    this.loading = false; //Css propriedades
                })
                .catch((err) => { });
        },

        async exportExcel() {
            if (this.productService.length > 0) {
                var props = this.this_props;

                var excel_dados = [];
                var retails = [];

                for (var i = 1; i <= this.total_pages; i++) {
                    props.page = i;

                    await ProductsService.comparative(props)
                        .then((result) => {
                            const resultValidation = this.$root.resultValidationRequest(result);

                            if (resultValidation != true) {
                                this.$store.dispatch("alert_error", {
                                    message: resultValidation,
                                    time: this.time,
                                });

                                return;
                            }
                            result.data.data = result.data.data.map((item) => {
                                item.products.map((product) => {
                                    retails.push(product.retail);

                                    let prices = [];

                                    prices.push({ price: product.amount });
                                    prices.push({ price: product.amount_discount });
                                    prices.push({ price: product.amount_extra });

                                    let price_min = prices.reduce((prev, current) => {
                                        return prev = parseFloat(prev.price) < parseFloat(current.price) ? prev : current.price == 0 ? prev : current;
                                    })

                                    console.log(price_min);

                                    product.min = price_min.price;

                                    return product;
                                });

                                return item;
                            });

                            excel_dados.push(result.data.data);

                            console.log(this.price)
                            console.log(excel_dados);
                        })
                        .catch((err) => { });
                }

                var retails_not_duplicados = retails.filter(function (este, i) {
                    return retails.indexOf(este) === i;
                });

                const wb = XLSX.utils.book_new();
                wb.Props = {
                    Title: "Lexart - comparativa de precios",
                    Subject: "Comparativa de precios",
                    Author: "Lexart",
                    CreatedDate: new Date(),
                };
                wb.SheetNames.push("comparativa");

                var ws_data = [];
                var col = [
                    "Producto",
                    "Brand",
                    "Area",
                    "Division",
                    "Categoria",
                    "Feature 1",
                    "Feature 2",
                    "Feature 3",
                    "Feature 4",
                    "Feature 5",
                ];
                retails_not_duplicados.map((item) => {
                    col.push(item);
                });
                ws_data.push(col);

                excel_dados.map((item) => {
                    item.map((item) => {
                        var newOBJ = [];

                        newOBJ.push(item.producto);
                        newOBJ.push(item.brand);
                        newOBJ.push(item.area);
                        newOBJ.push(item.division);
                        newOBJ.push(item.categoria);
                        newOBJ.push(item.feature_1);
                        newOBJ.push(item.feature_2);
                        newOBJ.push(item.feature_3);
                        newOBJ.push(item.feature_4);
                        newOBJ.push(item.feature_5);

                        retails_not_duplicados.map((column) => {
                            var val = null;
                            var total_0 = 0;
                            var total_productos = 0;
                            if (item.products.length > 1) {
                                var recebe_obj_column = [];

                                item.products.map((producto) => {
                                    total_productos++;

                                    if (producto.retail == column) {
                                        val = parseFloat(
                                            producto[this.price.method]
                                        ).toFixed(2);
                                        recebe_obj_column.push(val);

                                        if (
                                            parseFloat(
                                                producto[this.price.method]
                                            ) == 0
                                        ) {
                                            total_0++;
                                        }
                                    }

                                    if (item.products.length == total_0) {
                                        newOBJ.push(0.0);
                                    }
                                });
                                if (recebe_obj_column.length > 1) {
                                    var min = recebe_obj_column
                                        .map(Number)
                                        .reduce(function (a, b) {
                                            return Math.min(a, b);
                                        });
                                    newOBJ.push(parseFloat(min).toFixed(2));
                                } else if (recebe_obj_column.length == 1) {
                                    newOBJ.push(
                                        parseFloat(
                                            recebe_obj_column[0]
                                        ).toFixed(2)
                                    );
                                } else {
                                    newOBJ.push("---");
                                }
                            } else {
                                item.products.map((producto) => {
                                    if (producto.retail == column) {
                                        newOBJ.push(
                                            parseFloat(
                                                producto[this.price.method]
                                            ).toFixed(2)
                                        );
                                    } else {
                                        newOBJ.push("---");
                                    }
                                });
                            }
                        });

                        ws_data.push(newOBJ);
                    });
                });

                var ws = XLSX.utils.aoa_to_sheet(ws_data);
                wb.Sheets["comparativa"] = ws;

                var wbout = XLSX.write(wb, {
                    bookType: "xlsx",
                    type: "binary",
                });

                function s2ab(s) {
                    var buf = new ArrayBuffer(s.length);
                    var view = new Uint8Array(buf);
                    for (var i = 0; i != s.length; ++i)
                        view[i] = s.charCodeAt(i) & 0xff;
                    return buf;
                }

                saveAs(
                    new Blob([s2ab(wbout)], {
                        type: "application/octet-stream",
                    }),
                    this.this_props.date + " Comparativa de precios.xlsx"
                );
            } else {
                this.$store.dispatch("alert_error", {
                    message: "test",
                    time: 2000,
                });
            }
        },
    },
};
</script>
<style lang=""></style>
