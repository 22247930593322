<template>
    <div>
        <transition name="fade">
            <div
                v-if="loading == true && total > 0"
                :class="'container_toast_carge ' + toast"
            >
                <Notification :count="total_dados_cargados" :total="total" />
            </div>
        </transition>
        <div class="container-flex">
            <!-- @_____ == function callback -->
            <Query
                @getObtener="getObtener"
                :btnLeftLabel="'Buscar'"
                :type="'historico'"
                @click_export="exportExcel()"
                @limpiar_tabla="limpiarTable()"
                :disabledExport="productService.length > 0"
            ></Query>

            <transition name="fade">
                <SkuComponent v-if="total > 0" :sku="total"></SkuComponent>
            </transition>
        </div>
        <!-- @_____ == function callback -->

        <div
            class="container-custom"
            v-if="
                (productService.length <= 0 && loading == true) ||
                loading_pagination == true
            "
        >
            <ProgressSpinner />
        </div>

        <div v-if="productService.length > 0 && loading_pagination == false">
            <Table :data="productService" :options="optionsTable"></Table>
        </div>

        <div v-if="productService.length > 0 && loading_pagination == false">
            <Paginator
                v-if="loadingPaginationForced == false"
                :total_pages="total_pages"
                :total_dados="total_dados"
                :page_init="current_page"
                @handler="handlerPaginator"
            ></Paginator>
        </div>
    </div>
</template>
<script>
import Dropdown from "primevue/dropdown";
import MultiSelect from "primevue/multiselect";
import Button from "primevue/button";
import Query from "@/components/Query/QueryCompHisto.vue";
import Table from "@/components/Tables/TableHistorico.vue";
import ProgressSpinner from "primevue/progressspinner";
import Paginator from "@/components/Paginator.vue";
import Notification from "@/components/NotificationDataHandler/Notification.vue";
import SkuComponent from "@/components/SkuComponent.vue";

import IndexDB from "@/services/IndexDBService";

//SERVICES

import { ProductsService } from "@/services/Taxonomia/ProductsService";

export default {
    components: {
        Dropdown,
        MultiSelect,
        Button,
        Query,
        Table,
        ProgressSpinner,
        Paginator,
        Notification,
        SkuComponent,
    },
    data() {
        return {
            // DATA - API
            productService: [],
            loading: false,

            paisQuery: null,
            price: null,

            toast: "",

            //Alert
            time: 2000,

            request: false,
            optionsTable: null,
            total_dados_cargados: 0,

            total: 0,

            total_dados: 0,
            current_page: 1,
            total_pages: null,

            this_props: null,

            loading_pagination: false,

            date_desde: null,
            date_hasta: null,

            multi_request: false,

            loadingPaginationForced: false,
            limit_dados: 5000,
        };
    },

    destroyed() {
        IndexDB.clearStore("historico", "DBTO");
    },

    created() {
        IndexDB.createDB(
            () => {},
            "historico",
            "DBTO",
            "categoria, producto, fecha, retail",
            ["categoria", "producto", "fecha", "retail"],
            false,
            { autoIncrement: true, keyPath: "taxo_id" }
        );
    },

    methods: {
        //QUERY BTN

        getObtener(value) {
            this.productService = [];

            if (!value.country) {
                this.$store.dispatch("alert_error", {
                    //Alert
                    message: "Seleccione el país primero",
                    time: this.time, //Padrao 2000
                });
            } else if (!value.retail) {
                this.$store.dispatch("alert_error", {
                    //Alert
                    message: "Seleccione las retail primero",
                    time: this.time, //Padrao 2000
                });
            } else if (!value.category_abm  ||  value.category_abm.length == 0) {
                this.$store.dispatch("alert_error", {
                    //Alert
                    message: "Seleccione las categoría primero",
                    time: this.time, //Padrao 2000
                });
            } else if (!value.date.desde && !value.date.hasta) {
                this.$store.dispatch("alert_error", {
                    //Alert
                    message: "Seleccionar fechas",
                    time: this.time, //Padrao 2000
                });
            } else if (!this.multi_request == false) {
                this.$store.dispatch("alert_error", {
                    //Alert
                    message: "Espere un momento",
                    time: this.time, //Padrao 2000
                });
            } else {
                this.optionsTable = value.options;

                var dateDesde = value.date.desde;
                var dateHasta = value.date.hasta;

                var timeDiff = Math.abs(dateHasta.getTime() - dateDesde.getTime());
                var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

                let days = parseInt(diffDays);

                days = days == 1 ? days + 1 : days;

                let limit = 300 / days;
                limit = Math.ceil(limit);

                dateDesde = dateDesde.toISOString();
                dateDesde = dateDesde.split("T");
                dateDesde = dateDesde[0];

                this.date_desde = dateDesde;

                dateHasta = dateHasta.toISOString();
                dateHasta = dateHasta.split("T");
                dateHasta = dateHasta[0];

                this.date_hasta = dateHasta;

                var country = value.country;

                if (value.retail != null) {
                    var retails = value.retail.map((item) => {
                        return item.retail;
                    });
                } else {
                    var retails = [];
                }
                    
                var categories = value.category_abm.map((item) => {
                    return item.id;
                });

                if (value.brand != null) {
                    var brands = value.brand.map((item) => {
                        return item.id;
                    });
                } else {
                    var brands = [];
                }

                var obj = {
                    date: { desde: dateDesde, hasta: dateHasta },
                    country: country,
                    retails: retails,
                    categories: categories,
                    brands: brands,
                    limit: this.limit_dados,                    
                    
                };

                IndexDB.clearStore("historico", "DBTO");

                this.total_pages = null;
                this.total_dados = 0;
                this.loading = true;
                this.multi_request = true;
                this.total_dados_cargados = 0;
                

                ProductsService.historico(obj, this.limit_dados, 0, true)
                    .then((result) => {
                        const dataAmountLimit = 60000;
                        this.total_dados = 0 + " / " + result.data.count;
                        this.total = result.data.count;
                        if (result.data.count < dataAmountLimit && result.data.count > 0) {
                            this.nextHistoricoRequest(
                                result,
                                obj,
                                result.data.count,
                                this.limit_dados
                            ).catch(err => {
                                this.multi_request = false;
                                this.loading = false;
                                this.$store.dispatch("alert_error", {
                                    message: "No fue posible obtener los datos",
                                    time: this.time,
                                });
                            });
                        } else if (result.data.count >= dataAmountLimit) {
                            this.endLoading();
                            this.$store.dispatch("alert_error", {
                                message:
                                    `Su búsqueda arrojó más de ${dataAmountLimit} de productos, no fue posible mostrarlos.`,
                                time: 7000,
                            });
                            this.$store.dispatch("alert_success_bottom", {
                                message:
                                    "Te aconsejamos que reduzcas la diferencia de fechas.",
                                time: 7000,
                            });
                        } else {
                            this.endLoading();
                            this.$store.dispatch("alert_error", {
                                message: "No encontramos datos",
                                time: 2000,
                            });
                        }
                    })
                    .catch((err) => {
                       this.multi_request = false;
                       this.loading = false;

                        this.$store.dispatch("alert_error", {
                            message: "No fue posible obtener los datos",
                            time: this.time,
                        });
                    });
            }
        },

        handlerPaginator(page) {
            this.loading_pagination = true;
            var offset = 0;
            var limit = 20;

            offset = (page - 1) * 20;
            console.log("HANDLER PAGE => ", offset, page, this.current_page);
            this.current_page = page;

            IndexDB.getPagination(
                offset, // POR QUE O OFFSET É IGUAL A 0 SE A PAGINA "1 == 0 / 1-1 == 0"
                limit,
                this.getPagination,
                this.total_dados_cargados,
                "historico",
                "DBTO",
                false,
                "categoria, producto, fecha, retail"
            );

            setTimeout(() => {
                this.loading_pagination = false;
            }, 2000);
        },

        endLoading() {
            this.total_pages = null;
            this.total_dados = 0;
            this.loading = false;
            this.multi_request = false;
            this.total_dados_cargados = 0;
        },

        getPagination(results, offset, total_pages, total = false) {
            var id = 0;
            this.loadingPaginationForced = true;
            results.map((item) => {
                id++;
                console.log(id);
                item.class = id % 2 === 0 ? "linea_gris" : "linea_white"; // VERIFICA SE É PAR OU IMPAR
            });
            this.productService = [];

            console.log(offset);
            this.total_pages = total_pages;

            if (total == true) {
                this.productService = results;
                this.$nextTick(() => {
                    this.loadingPaginationForced = false;
                    console.log(this.loadingPaginationForced);
                });
                return;
            }

            this.productService = results;
            this.$nextTick(() => {
                this.loadingPaginationForced = false;
            });

            return;
        },

        async request_from_offset(offset, query_params, total_dados, limit) {
            const request = await ProductsService.historico(query_params, limit, offset)
                .then((result) => {
                    const data_result = result.data.data;
                    return data_result;
                })

            return request;
        },

        returnIndexAddPromise(values, storeName, nameDB) {
            const promise = new Promise((resolve, reject) => {
                let valuesLength = values.length;
                let add = 0;
                values.map((item) => {
                    IndexDB.itemADD(
                        item,
                        () => {
                            add++;
                            if (add == valuesLength) {
                                IndexDB.getPagination(
                                    0,
                                    20,
                                    this.getPagination,
                                    this.total_dados_cargados,
                                    "historico",
                                    "DBTO",
                                    false,
                                    "categoria, producto, fecha, retail"
                                );
                                resolve(valuesLength);
                            }
                        },
                        storeName,
                        nameDB
                    );
                });
            });

            return promise;
        },

        async nextHistoricoRequest(result, obj, total_dados, limit) {
            this.total_dados_cargados = 0;
            let indexDB_dados_cargados = 0;
            for (var i = 0; this.total_dados_cargados < total_dados; i += limit) {
                const promise = await this.request_from_offset(
                    i,
                    obj,
                    total_dados,
                    limit
                );

                const promiseAddIndexedDB = await this.returnIndexAddPromise(
                    promise,
                    "historico",
                    "DBTO"
                );

                this.total_dados_cargados += promiseAddIndexedDB;
                this.total_dados = this.total_dados_cargados + " / " + total_dados;

                IndexDB.getPagination(
                    0,
                    20,
                    this.getPagination,
                    this.total_dados_cargados,
                    "historico",
                    "DBTO",
                    false,
                    "categoria, producto, fecha, retail"
                );

                if (this.total_dados_cargados == total_dados) {
                    setTimeout(() => {
                        this.toast = "out";
                    }, 2000);
                    setTimeout(() => {
                        this.loading = false;
                        this.multi_request = false;
                        this.toast = "";
                    }, 3000);
                }
            }
        },

        closeToast() {
            if (this.loading == true) {
                this.toast = "out";
            }
        },

        exportExcel() {
            if (this.productService.length > 0) {
                const cols = [
                    "id",
                    "taxo_id",
                    "Retail",
                    "Area",
                    "Division",
                    "Categoria",
                    "Producto",
                    "Brand",
                    "Feature 1",
                    "Feature 2",
                    "Feature 3",
                    "Feature 4",
                    "Feature 5",
                    "md5 group",
                    "Precio lista",
                    "Precio Oferta",
                    "Precio Extra",
                    "Promo",
                    "Posición",
                    "Fecha",
                    "Enlace",
                    "stock",
                    "Sku",
                    "Unidad",
                ];

                const props = [
                    "id",
                    "taxo_id",
                    "retail",
                    "area",
                    "division",
                    "categoria",
                    "producto",
                    "brand",
                    "feature_1",
                    "feature_2",
                    "feature_3",
                    "feature_4",
                    "feature_5",
                    "md5_group",
                    "precio_de_lista",
                    "precio_oferta",
                    "precio_extra",
                    "promo",
                    "posicion",
                    "fecha",
                    "enlace",
                    "stock",
                    "sku",
                    "unidad",
                ];

                const name =
                    this.date_desde + " to " + this.date_hasta + " Historico de precios";

                IndexDB.exportExcel(name, "historico", "DBTO", cols, props);
            } else {
                this.$store.dispatch("alert_success", {
                    message: "Necesitamos que investigue algo para poder exportar",
                    time: 3000,
                });
            }
        },

        limpiarTable() {
            if (this.productService.length > 0) {
                this.productService = [];
                IndexDB.clearStore("historico", "DBTO");
            } else {
                this.$store.dispatch("alert_success", {
                    message:
                        "Necesitamos que investigue algo para poder limpiar la table",
                    time: 3000,
                });
            }
        },
    },
};
</script>
<style lang=""></style>
