<template style="">
    <div class="table-box" v-on:scroll="scrollTable">
        {{propsToData}}
        <table class="table-comparativa">
            <thead>
                <tr>
                    <th v-on:click="setAsc(2)" ref="producto">Producto
                        <i
                            class="pi pi-sort-amount-up-alt"
                            :style="'font-size: 1rem; color: ' + asc_color2"
                        ></i>
                    </th>
                    <th v-on:click="setAsc(1)" :class="classTableElevation" :style="leftAlign">
                        Brand
                        <i
                            class="pi pi-sort-amount-up-alt"
                            :style="'font-size: 1rem; color: ' + asc_color1"
                        ></i>
                    </th>
                    <th>Feature 1</th>
                    <th>Feature 2</th>
                    <th>Feature 3</th>
                    <th>Feature 4</th>
                    <th>Feature 5</th>
                    <th v-for="field in separator(pricesRetails)" :key="field">
                        {{ field }}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="item in values"
                    :key="item.id"
                    :class="rowClass(item)"
                >
                    <td>
                        {{ item.producto }}
                    </td>
                    <td :style="leftAlign" :class="classTableElevation">
                        <Chip :label="item.brand" class="chip-custom"></Chip>
                    </td>
                    <td>
                        {{ item.feature_1 }}
                    </td>
                    <td>
                        {{ item.feature_2 }}
                    </td>
                    <td>
                        {{ item.feature_3 }}
                    </td>
                    <td>
                        {{ item.feature_4 }}
                    </td>
                    <td>
                        {{ item.feature_5 }}
                    </td>
                    <td v-for="field in separator(pricesRetails)" :key="field">
                        <div v-if="selector(item, field, price).link">
                            <a
                                :href="selector(item, field, price).link"
                                target="_blank"
                                :class="
                                    getCssLowerAndBig(
                                        selector(item, field, price)
                                    )
                                "
                                ><span
                                    v-if="
                                        selector(item, field, price)
                                            .lowerPrice == 0
                                    "
                                    >0,00</span
                                >
                                <span v-else>{{
                                    selector(item, field, price).lowerPrice
                                }}</span>
                            </a>
                        </div>
                        <div v-else>
                            {{ selector(item, field, price).lowerPrice }}
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
//Components primevue
import DataTable from "primevue/datatable";
import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputtext";
import Column from "primevue/column";
import MultiSelect from "primevue/multiselect";
import Chip from "primevue/chip";
import Toolbar from "primevue/toolbar";
import Button from "primevue/button";
import Checkbox from "primevue/checkbox";
import SplitButton from "primevue/splitbutton";
import ToggleButton from "primevue/togglebutton";

import AutoComplete from "primevue/autocomplete";

//Services
import { ProductsService } from "../../services/Taxonomia/ProductsService";
import { CategoriaServiceABM } from "../../services/CategoriaService";
import { FeatureService } from "../../services/FeatureService";
import { BrandService } from "../../services/BrandService";
import UsersServices from "../../services/UsersServices";

export default {
    components: {
        DataTable,
        Dropdown,
        InputText,
        Column,
        MultiSelect,
        Chip,
        Toolbar,
        Button,
        Checkbox,
        SplitButton,
        ToggleButton,
        AutoComplete,
    },

    props: ["data", "price", "pricesRetails"],

    data() {
        return {
            paisSelectColumn: null,

            //Alert
            time: 2000,

            retails_prices: [
                {
                    id: "918273iuh",
                    prices: [],
                },
            ],

            price_link: {
                method: "link",
            },

            asc1: "brand",
            asc_color1: "#2196f3",

            asc2: "producto",
            asc_color2: "#2196f3",

            values: [],
            classTableElevation: "",
            leftAlign: ""
        };
    },

    watch: {
        asc1: function (newVal, olvVal) {
            this.asc_color1 = newVal !== "brand" ? "gray" : "#2196f3";
        },

        asc2: function (newVal, olvVal) {
            this.asc_color2 = newVal !== "producto" ? "gray" : "#2196f3";
        },
    },

    computed: {
        propsToData() {
            this.values = this.returnAsc(this.data);
        }
    },

    mounted() {
        this.leftAlign = "left: " + this.$refs.producto.clientWidth + "px;";
    },

    methods: {
        scrollTable(e) {
            if(e.srcElement.scrollLeft > 0) {
                this.classTableElevation = "table-moved";
                this.leftAlign = "left: " + this.$refs.producto.clientWidth + "px;";
                return;
            }

            this.classTableElevation = "";

            return;
        },

        setAsc(asc) {
            if (asc == 1) {
                this["asc" + asc] = this["asc" + asc] == "brand" ? "" : "brand";
                return;
            }

            if (asc == 2) {
                this["asc" + asc] = this["asc" + asc] == "producto" ? "" : "producto";
                
                
                return; 
            }
            return;
        },

        returnValueIndex(values, az, prop) {
            let propIndex = [];
            values.map((item) => {
                propIndex.push(item[prop]);
            });

            propIndex = propIndex.filter(
                (ele, pos) => propIndex.indexOf(ele) == pos
            );

            return az == true ? (propIndex = propIndex.sort()) : propIndex;
        },

        ascFunc(values, prop) {
            let grupos = [];
            let propIndex = this.returnValueIndex(values, true, prop);

            values.reduce((acumulador, current) => {
                if (!acumulador[prop]) {
                    acumulador = current;
                }

                if (acumulador[prop] == current[prop]) {
                    if (!grupos[acumulador[prop]]) grupos[acumulador[prop]] = [];
                    grupos[acumulador[prop]].push(current);
                } else {
                    if (!grupos[current[prop]]) grupos[current[prop]] = [];
                    grupos[current[prop]].push(current);
                }

                return (acumulador = current);
            }, {});

            return { grupos, propIndex };
        },

        returnLineaColor(values) {
            let id = 0;

            let arr = values.map((item) => {
                id++;
                item.class = id % 2 === 0 ? "linea_gris" : "linea_white";

                return item;
            })

            return arr;
        },

        returnAsc(values) {
            if (this.asc1 == "brand") {
                let ascFunc = this.ascFunc(values, this.asc1);
                let valuesAsc = [];
                let grupos = ascFunc.grupos;
                let propIndex = ascFunc.propIndex;


                if(this.asc2 == "producto") {
                    propIndex.map((index) => {
                        return grupos[index].sort((prev, curr) => {
                            if (prev.producto > curr.producto) {
                                return 1;
                            }
                            if (prev.producto < curr.producto) {
                                return -1;
                            }
                            return 0;
                        })
                    });
                }

                let id = 0;
                propIndex.map((index) => {
                    grupos[index].map((item) => {
                        id++;
                        item.class =
                            id % 2 === 0 ? "linea_gris" : "linea_white";
                    });

                    valuesAsc.push(...grupos[index]);
                });

                values = valuesAsc;
                values = this.returnLineaColor(values);
                return values;
            }

            if(this.asc2 == "producto") {
                values.sort((prev, curr) => {
                    if (prev.producto > curr.producto) {
                        return 1;
                    }
                    if (prev.producto < curr.producto) {
                        return -1;
                    }
                    return 0;
                });

                return values = this.returnLineaColor(values);
            }

            values.sort(() => (Math.random() > .5) ? 1 : -1);
            values = this.returnLineaColor(values);    
            return values;
        },

        separator(pricesRetails) {
            var newObj = pricesRetails.filter(function (retail, i) {
                return pricesRetails.indexOf(retail) === i;
            });

            return newObj;
        },

        filter_ecuals(arrayOfObjects, field) {
            const filterdArrayOfObjects = arrayOfObjects.filter((object) => {
                if (object.retail === field) return object;
            });

            return filterdArrayOfObjects;
        },

        filter_menor_do_maior(obj, price) {
            let retails = this.separator(this.pricesRetails);

            let array_value_menor = [];
            retails.map((field) => {
                let obj_filtrado = this.filter_ecuals(obj, field);

                if (obj_filtrado.length > 0) {
                    let lower_price = obj_filtrado.reduce(
                        (acumulador, objeto) => {
                            acumulador =
                                parseFloat(objeto[price.method]) <
                                    parseFloat(acumulador[price.method]) &&
                                parseFloat(objeto[price.method]) > 0
                                    ? objeto
                                    : acumulador;
                            return acumulador;
                        }
                    );
                    array_value_menor.push(lower_price);
                }
            });

            return array_value_menor;
        },

        getCssLowerAndBig(product) {
            if (product.isBiggester == true) {
                return "link isBiggesterValue ";
            }

            if (product.isLower == true) {
                return "link isLowerValue ";
            }

            return "link";
        },

        getLowerAndBig(obj, price) {
            let filter_lower_price = this.filter_menor_do_maior(obj, price);

            if (obj.length > 1) {
                let FilterGreaterThanZero = obj.filter(
                    (item) => parseFloat(item[price.method]) !== 0
                );
                obj =
                    FilterGreaterThanZero.length > 0
                        ? FilterGreaterThanZero
                        : obj;
            }

            let lower_price = obj.reduce((acumulador, objeto) => {
                acumulador =
                    parseFloat(objeto[price.method]) <
                        parseFloat(acumulador[price.method]) &&
                    parseFloat(objeto[price.method]) > 0
                        ? objeto
                        : acumulador;
                return acumulador;
            });

            let biggest_price;
            if (filter_lower_price.length > 0) {
                biggest_price = filter_lower_price.reduce(
                    (acumulador, objeto) => {
                        acumulador =
                            parseFloat(objeto[price.method]) >
                                parseFloat(acumulador[price.method]) &&
                            parseFloat(objeto[price.method]) > 0
                                ? objeto
                                : acumulador;
                        return acumulador;
                    }
                );

                return { lower_price, biggest_price };
            }

            if (filter_lower_price.length === 0) {
                return { lower_price, test: "test" };
            }
        },

        factorProductPriceDiffMethods(obj) {
            let product_price = [];
            obj.map((product) => {
                product_price.push({
                    price: product[product.method],
                    method: product.method,
                    md5_link: product.md5_link,
                });
            });

            return product_price;
        },

        factorProductPrice(product_price, prices, obj) {
            if (product_price.length > 0) {
                return product_price;
            }

            prices.map((method) => {
                obj.map((product) => {
                    product_price.push({
                        price: product[method],
                        method: method,
                        md5_link: product.md5_link,
                    });
                });
            });

            return product_price;
        },

        getProductPriceNotField(
            obj,
            prices,
            big = false,
            returnPrices = false,
            debug = false,
            product_price = []
        ) {
            product_price =
                product_price.length > 0
                    ? this.factorProductPrice(product_price)
                    : this.factorProductPrice([], prices, obj);

            if (returnPrices == true) {
                return product_price;
            }

            if (product_price.length > 0) {
                let product_price_min = product_price.reduce(
                    (acumulador, objeto) => {
                        if (big == true) {
                            acumulador =
                                parseFloat(objeto.price) >
                                    parseFloat(acumulador.price) &&
                                parseFloat(objeto.price) > 0
                                    ? objeto
                                    : acumulador;
                            return acumulador;
                        }
                        acumulador =
                            parseFloat(objeto.price) <
                                parseFloat(acumulador.price) &&
                            parseFloat(objeto.price)
                                ? objeto
                                : acumulador;
                        acumulador =
                            parseFloat(objeto.price) <
                                parseFloat(acumulador.price) &&
                            parseFloat(objeto.price) > 0
                                ? objeto
                                : acumulador;
                        return acumulador;
                    }
                );

                product_price = obj.filter((product) => {
                    if (
                        product.md5_link == product_price_min.md5_link &&
                        product[product_price_min.method] ==
                            product_price_min.price
                    ) {
                        product.method = product_price_min.method;
                        return product;
                    }

                    return;
                });

                return product_price;
            }

            return product_price;
        },

        selector(obj, field, price) {
            let obj_filtrado = this.filter_ecuals(obj.products, field);

            if (obj_filtrado.length <= 0) {
                return {
                    lowerPrice: "---",
                };
            }

            if (price.id == 4 && price.method == "min") {
                const prices = ["amount", "amount_discount", "amount_extra"];

                let product_price = this.getProductPriceNotField(
                    obj_filtrado,
                    prices
                );
                let product_lower_price = this.getProductPriceNotField(
                    obj.products,
                    prices
                );

                product_price = product_price[0];
                product_lower_price = product_lower_price[0];

                let retails = this.separator(this.pricesRetails);
                let price_low_array = [];
                let retailsPriceLowArray = [];
                let price_lot_all_retails = [];

                retails.map((field) => {
                    let products_filtrado = this.filter_ecuals(
                        obj.products,
                        field
                    );

                    if (products_filtrado.length > 0) {
                        let product_low_price = this.getProductPriceNotField(
                            products_filtrado,
                            prices
                        );
                        price_low_array.push(product_low_price[0]);
                    }

                    let product_prices_retail = [];

                    products_filtrado.map((product) => {
                        product_prices_retail.push(
                            ...this.getProductPriceNotField(
                                [product],
                                prices,
                                false,
                                true,
                                false
                            )
                        );
                    });

                    product_prices_retail = this.getProductPriceNotField(
                        products_filtrado,
                        prices,
                        false,
                        false,
                        false,
                        product_prices_retail
                    );
                    price_lot_all_retails.push(...product_prices_retail);
                });

                price_lot_all_retails = this.factorProductPriceDiffMethods(
                    price_lot_all_retails
                );

                price_low_array = this.getProductPriceNotField(
                    price_low_array,
                    prices
                );

                let product_biggester_price = this.getProductPriceNotField(
                    obj.products,
                    prices,
                    true,
                    false,
                    false,
                    price_lot_all_retails
                );

                price_low_array = price_low_array[0];
                product_biggester_price = product_biggester_price[0];

                let isLower =
                    product_price[product_price.method] ==
                    price_low_array[product_lower_price.method]
                        ? true
                        : false;
                let isBiggester =
                    product_price[product_price.method] ==
                    product_biggester_price[product_biggester_price.method]
                        ? true
                        : false;

                return {
                    lowerPrice: product_price[product_price.method],
                    link: product_price.link,
                    isLower,
                    isBiggester,
                };
            }

            let lower_biggest = this.getLowerAndBig(obj.products, price);

            var preco = {};

            if (obj_filtrado.length === 1) {
                const isLower =
                    parseFloat(lower_biggest.lower_price[price.method]) ===
                        parseFloat(obj_filtrado[0][price.method]) &&
                    parseFloat(obj_filtrado[0][price.method]) > 0;
                const isBiggester =
                    parseFloat(lower_biggest.biggest_price[price.method]) ===
                        parseFloat(obj_filtrado[0][price.method]) &&
                    parseFloat(obj_filtrado[0][price.method]) > 0;

                return {
                    lowerPrice: obj_filtrado[0][price.method],
                    link: obj_filtrado[0].link,
                    isLower,
                    isBiggester,
                };
            }

            if (obj_filtrado.length > 1) {
                obj_filtrado.reduce((acumulador, objeto) => {
                    acumulador =
                        parseFloat(objeto[price.method]) <
                            parseFloat(acumulador[price.method]) &&
                        parseFloat(objeto[price.method]) > 0
                            ? objeto
                            : acumulador;
                    return (preco = acumulador);
                });
            }

            const isLower =
                parseFloat(lower_biggest.lower_price[price.method]) ===
                    parseFloat(preco[price.method]) &&
                parseFloat(preco[price.method]) > 0;
            const isBiggester =
                parseFloat(lower_biggest.biggest_price[price.method]) ===
                    parseFloat(preco[price.method]) &&
                parseFloat(preco[price.method]) > 0;

            return {
                lowerPrice: preco[price.method],
                link: preco.link,
                isLower,
                isBiggester,
            };
        },

        rowClass(data) {
            return data.class;
        },
    },
};
</script>