<template>
    <div class="p-mt-4">
        <div class="contentSKU">
            <ul>
                <li>{{sku}}</li>
                <li>SKU</li>
            </ul>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        sku: Number,
    }
}
</script>
<style>
    .contentSKU > ul {
        padding: 0px;
        list-style: none;
        text-align: center;
        border-radius: 10px;
        box-shadow: 0px 6px 9px 0px #dbdbdb;
        min-width: 100px;
        background: white;
    }

    .contentSKU > ul > li:first-child {
        padding: 15px;
    }

    .contentSKU > ul > li:last-child {
        padding: 7px;
        background: #2196f3;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        color: white;
    }
</style>