<template>
    <div class="content-query">
        <div class="p-mt-4 container-flex">
            <Dropdown class="custom-dropdown filter mr-2 custom-input-max-min" v-model="paisSelect" :options="country"
                @input="inputCountry" @hide="hidePais" optionLabel="name" filter placeholder="País"
                :disabled="optDisabled.pais" />

            <MultiSelect v-model="selectedRetail" class="custom-multiselect filter mr-2 custom-input-max-min-300"
                :options="retail" optionLabel="retail" placeholder="Retail" filter display="chip" @show="showRetail"
                @hide="hideRetail" :disabled="optDisabled.retail" />

            <AutoComplete v-if="type == 'comparativo' || type == 'historico'" v-model="selectedArea" :suggestions="area"
                :disabled="optDisabled.area" :dropdown="true" @complete="searchArea($event)"
                @item-select="selectArea($event)" field="name" placeholder="Area" class="
                    custom-multiselect custom-auto-complete
                    disabled
                    no-border
                    mr-2
                    custom-input-max-min
                " />

            <AutoComplete v-if="type == 'comparativo' || type == 'historico'" v-model="selectedDivision"
                :suggestions="division" :disabled="optDisabled.division" :dropdown="true"
                @complete="searchDivision($event)" @item-select="selectDivision($event)" field="name"
                placeholder="Division" class="
                    custom-multiselect custom-auto-complete
                    disabledv 
                    no-border 
                    mr-2 
                    custom-input-max-min
                " />

            <AutoComplete v-if="type == 'comparativo'" v-model="selectedCategoria_abm" :suggestions="categoria_abm"
                :dropdown="true" :disabled="optDisabled.categoria" @complete="searchCategoria($event)" field="name"
                placeholder="Categoria" class="
                    custom-multiselect custom-auto-complete
                    disabled
                    no-border 
                    custom-input-max-min-300
                " />

            <MultiSelect v-if="type == 'historico'" v-model="selectedCategoria_abm"
                class="custom-multiselect custom-input-max-min-300 filter" :options="categoria_abm" optionLabel="name"
                placeholder="Categoria" display="chip" :filter="true" @filter="searchCategoria" @show="searchCategoria"
                :disabled="optDisabled.categoria" />
        </div>
        <div v-if="type == 'comparativo' || type == 'historico'" class="mt-2 container-flex">
            <MultiSelect v-if="type == 'comparativo' || type == 'historico'" v-model="selectedBrand_abm"
                class="custom-multiselect filter mr-2 custom-input-max-min" :disabled="false" :options="brand_abm"
                optionLabel="name" placeholder="Marcas" filter @filter="searchBrand" display="chip" @show="showRetail"
                @hide="hideRetail" />

            <Dropdown v-if="type == 'comparativo'" v-model="selectedPrice"
                class="custom-dropdown mr-2 custom-input-max-min" :options="priceList" optionLabel="name"
                placeholder="Precio" />
            <div v-if="type == 'historico'">
                <SelectorTypeTable @returnOptions="changeOptions"></SelectorTypeTable>
            </div>
            <Calendar v-model="dateHoy" v-if="type == 'comparativo'" class="
                    custom-multiselect
                    custom-auto-complete
                    custom-calendar
                    custom-input-max-min
                " placeholder="Date" :showIcon="true" @date-select="compDatePickerSelect" :maxDate="maxDateValue" />
            <Calendar v-model="dateDesde" v-if="type == 'historico'" class="
                    custom-multiselect custom-auto-complete custom-calendar custom-input-max-min
                    mr-2
                " placeholder="Fecha desde" :showIcon="true" @date-select="dateDesdeSelect" :maxDate="maxDateValue" />

            <Calendar v-model="dateHasta" v-if="type == 'historico'"
                class="custom-multiselect custom-auto-complete custom-calendar custom-input-max-min"
                placeholder="Fecha hasta" :showIcon="true" :disabled="datePicker2Disabled"
                @date-select="dateHastaSelect" :maxDate="maxDateValue" />
        </div>
        <div
            v-if="type == 'comparativo' || type == 'historico'"
            class="mt-2 container-flex"
        >
            <Button
                class="button-custom mr-2 custom-button-max-min-200"
                :label="btnLeftLabel"
                @click="getQuery"
            ></Button>
            <Button
                class="button-custom mr-2 custom-button-max-min-200"
                label="Limpiar Filtros"
                v-on:click="clearCampos"
            ></Button>
            <Button
                class="button-custom mr-2 button-custom-green custom-button-max-min-200"
                label="Exportar"
                :disabled="!disabledExport"
                @click="$emit('click_export')"
            ></Button>
            <Button
                class="button-custom custom-button-max-min-200"
                @click="$emit('limpiar_tabla')"
                label="Limpiar Tabla"
            ></Button>
        </div>
    </div>
</template>
<script>
import Dropdown from "primevue/dropdown";
import MultiSelect from "primevue/multiselect";
import Button from "primevue/button";
import AutoComplete from "primevue/autocomplete";
import { AreaService } from "../../services/AreaService";
import { DivisionService } from "../../services/DivisionService";
import { OldBrandService } from "../../services/Taxonomia/OldBrandService";
import { BrandService } from "../../services/BrandService";
import Calendar from "primevue/calendar";
import { CategoriaServiceABM } from "../../services/CategoriaService";
import Checkbox from "primevue/checkbox";
import { CountryService } from "../../services/Taxonomia/CountryService";
import { RetailsService } from "../../services/Taxonomia/RetailsService";
import SelectorTypeTable from "@/components/SelectorTypeTable";

export default {
    components: {
        Dropdown,
        MultiSelect,
        Button,
        AutoComplete,
        Calendar,
        Checkbox,
        SelectorTypeTable,
    },
    data() {
        return {
            selectedDivision: null,
            selectedArea: null,
            selectedBrand: null,
            selectedCategoria: null,
            paisSelect: null,
            selectedRetail: null,
            selectedRetailShow: null,
            selectedCategoria_abm: null,
            selectedBrand_abm: null,

            optDisabled: {
                pais: false,
                retail: true,
                area: false,
                division: true,
                categoria: true,
            },

            datePicker2Disabled: true,

            retail: [],
            area: [],
            division: [],
            categoria: [],
            country: [],

            datos: [],

            brand: [],

            brand_abm: [],

            datePicker: null,

            categoria_abm: [],

            selectedPrice: null,

            dateHoy: null,
            dateDesde: null,
            dateHasta: null,

            maxDateValue: null,

            priceList: [
                { name: "Precio Lista", id: 1, method: "amount" },
                { name: "Precio Oferta", id: 2, method: "amount_discount" },
                { name: "Precio Extra", id: 3, method: "amount_extra" },
                { name: "Precio Mínimo", id: 4, method: "min" },
            ],

            options: [],

            disabled: true,
        };
    },

    props: ["width", "type", "btnLeftLabel", "disabledExport"],

    created() {
        this.dateHoy = new Date();
        this.maxDateValue = new Date();

        if (this.type == "historico" || this.type == "comparativo") {
            BrandService.getAll()
                .then((result) => {
                    const resultValidation = this.$root.resultValidationRequest(result);

                    if (resultValidation != true) {
                        this.$store.dispatch("alert_error", {
                            message: resultValidation,
                            time: 5000,
                        });

                        return;
                    }
                    result.data.data = result.data.data.map((item) => {
                        item.name = item.name.toLowerCase();
                        return item;
                    });
                    this.brand_abm = result.data.data;
                })
                .catch((err) => { });
        }

        CountryService.getAll()
            .then((result) => {
                const resultValidation = this.$root.resultValidationRequest(result);

                if (resultValidation != true) {
                    this.$store.dispatch("alert_error", {
                        message: resultValidation,
                        time: this.time,
                    });

                    return;
                }
                this.country = result.data;
                console.log(this.country);
            })
            .catch((err) => { });

        this.dateHasta = this.getDate();

        var desde = new Date();
        desde.setDate(desde.getDate() - 1);

        this.dateDesde = desde;
    },

    methods: {
        changeOptions(options) {
            this.options = options;
        },

        inputCountry(val) {
            this.optDisabled.retail = false;
            this.selectedRetail = [];
            this.retail = [];

            RetailsService.getAll()
                .then((result) => {
                    const resultValidation = this.$root.resultValidationRequest(result);

                    if (resultValidation != true) {
                        this.$store.dispatch("alert_error", {
                            message: resultValidation,
                            time: 5000,
                        });

                        return;
                    }
                    this.retail = result.data.filter(
                        (item) => item.country == val.country
                    );
                })
                .catch((err) => { });
        },

        compDatePickerSelect(event) {
            var day = event.getUTCDate();
            var month = event.getUTCMonth();

            var dayActual = new Date().getUTCDate();
            var monthActual = new Date().getUTCMonth();

            var diff = dayActual - day;
            if (diff > 5 || monthActual != month) {
                this.$store.dispatch("alert_error", {
                    message:
                        "¿Aconsejamos no seleccionar fechas con diferencias de un máximo de 5 días?",
                    time: 3500,
                });
            } else {
            }
        },

        hidePais() {
            if (this.paisSelect != null) {
                this.optDisabled.retail = false;
            } else {
                this.optDisabled.retail = true;
            }
        },

        showRetail() {
            this.selectedRetailShow = this.selectedRetail;
        },

        hideRetail() {
            if (this.selectedRetail != null) {
                if (this.selectedRetailShow != this.selectedRetail) {
                    this.selectedCategoria = null;
                    this.selectedBrand = null;

                    if (this.selectedRetail.length) {
                        this.categoria = [];
                        this.brand = [];
                    }
                }
            }
        },

        searchArea(event) {
            var name = event.query;
            this.selectedDivision = [];
            this.selectedCategoria_abm = [];

            AreaService.getAutocomplete(name)
                .then((result) => {
                    const resultValidation = this.$root.resultValidationRequest(result);

                    if (resultValidation != true) {
                        this.$store.dispatch("alert_error", {
                            message: resultValidation,
                            time: 5000,
                        });

                        return;
                    }
                    result.data = result.data.map((item) => {
                        item.name = item.name.toLowerCase();
                        return item;
                    });
                    this.area = result.data;
                })
                .catch((err) => { });
        },

        selectArea(event) {
            this.selectedDivision = [];

            this.optDisabled.division = false;

            this.selectedCategoria_abm = [];

            this.optDisabled.categoria = true;
        },

        searchDivision(event) {
            var name = event.query;
            var area_id = this.selectedArea.id;

            this.selectedCategoria_abm = [];
            this.optDisabled.categoria = true;

            if (area_id != null) {
                DivisionService.getAutocomplete(name, area_id)
                    .then((result) => {
                        const resultValidation = this.$root.resultValidationRequest(result);

                        if (resultValidation != true) {
                            this.$store.dispatch("alert_error", {
                                message: resultValidation,
                                time: this.time,
                            });

                            return;
                        }
                        result.data = result.data.map((item) => {
                            item.name = item.name.toLowerCase();
                            return item;
                        });
                        this.division = result.data;
                    })
                    .catch((err) => { });
            }
        },

        selectDivision(event) {
            this.selectedCategoria_abm = [];

            this.optDisabled.categoria = false;
        },

        searchCategoria(event) {
            var name = event ? event.value : "";
            var division_id = this.selectedDivision.id;

            if (division_id != null) {
                CategoriaServiceABM.getAutocomplete(name, division_id)
                    .then((result) => {
                        const resultValidation = this.$root.resultValidationRequest(result);

                        if (resultValidation != true) {
                            this.$store.dispatch("alert_error", {
                                message: resultValidation,
                                time: this.time,
                            });

                            return;
                        }
                        this.categoria_abm = [];
                        result.data = result.data.map((item) => {
                            item.name = item.name.toLowerCase();
                            return item;
                        });
                        this.categoria_abm = result.data;

                        this.selectedCategoria_abm.map((item) => {
                            var check = false;
                            this.categoria_abm.map((categoria) => {
                                if (item.id == categoria.id) {
                                    check = true;
                                }
                            });

                            if (check == false) {
                                this.categoria_abm.push(item);
                            }
                        });
                    })
                    .catch((err) => { });
            }
        },

        searchBrand(event) {
            var name = event.value;

            BrandService.getAutocomplete(name)
                .then((result) => {
                    const resultValidation = this.$root.resultValidationRequest(result);

                    if (resultValidation != true) {
                        this.$store.dispatch("alert_error", {
                            message: resultValidation,
                            time: 5000,
                        });

                        return;
                    }
                    result.data = result.data.map((item) => {
                        item.name = item.name.toLowerCase();
                        return item;
                    });
                    this.brand_abm = result.data;
                })
                .catch((err) => { });
        },

        getQuery() {
            var query = [];

            //Country
            if (this.paisSelect != null) {
                query.country = this.paisSelect.country;
            }

            //Retail
            if (this.selectedRetail != null) {
                query.retail = this.selectedRetail;
            }

            //Category
            if (this.selectedCategoria != null) {
                query.category = this.selectedCategoria;
            }

            //Brand ou category_abm
            if (this.selectedBrand != null) {
                query.brand = this.selectedBrand;
            }

            if (this.selectedCategoria_abm != null) {
                query.category_abm = this.selectedCategoria_abm;
            }

            if (this.type == "historico") {
                query.date = { desde: this.dateDesde, hasta: this.dateHasta };
            } else {
                query.date = this.dateHoy;
            }
            if (this.selectedBrand_abm != null) {
                query.brand = this.selectedBrand_abm;
            }

            if (this.selectedPrice != null && this.type == "comparativo") {
                query.price = this.selectedPrice;
            }

            query.options = this.options;

            this.$emit("getObtener", query);
        },

        clear() {
            this.selectedRetail = null;

            if (this.type != "comparativo") {
                this.selectedDivision = null;
                this.selectedArea = null;
                this.selectedBrand = null;
                this.selectedCategoria = null;
                this.retail = [];
                this.area = [];
                this.division = [];
                this.categoria = [];

                this.optDisabled = {
                    pais: false,
                    retail: true,
                    area: false,
                    division: true,
                    categoria: true,
                };
            }
        },

        clearCampos() {
            this.selectedDivision = null;
            this.selectedArea = null;
            this.selectedBrand = null;
            this.selectedCategoria = null;
            this.paisSelect = null;
            this.selectedRetail = null;
            this.selectedRetailShow = null;
            this.selectedCategoria_abm = null;
            this.selectedBrand_abm = null;

            this.dateHoy = null;
            this.dateDesde = null;
            this.dateHasta = null;

            this.options = [];

            this.optDisabled = {
                pais: false,
                retail: true,
                area: false,
                division: true,
                categoria: true,
            };
        },

        disabledDaysController() {
            return [1];
        },

        dateHastaSelect(event) {
            var firstDate = this.dateDesde.getUTCDate();
            var lastDate = event.getUTCDate();

            var diff = lastDate - firstDate;

            if (diff > 5) {
                this.$store.dispatch("alert_error", {
                    message:
                        "¿Aconsejamos no seleccionar fechas con diferencias de un máximo de 5 días?",
                    time: 3000,
                });
            }
        },

        dateDesdeSelect(event) {
            this.datePicker2Disabled = false;
        },

        getDate(increment) {
            let date = new Date();
            return date;
        },
    },
};
</script>
<style lang=""></style>
