<template>
    <div class="selector-type-table">
        <div style="">
            <Checkbox
                id="unidad"
                inputId="unidad"
                name="unidad"
                value="unidad"
                v-model="options"
            />
            <label for="unidad">Unidad</label>
        </div>
        <div>
            <Checkbox
                id="sku"
                inputId="sku"
                name="sku"
                value="sku"
                v-model="options"
            />
            <label for="sku">Sku</label>
        </div>
        <div>
            <Checkbox
                id="stock"
                inputId="stock"
                name="stock"
                value="stock"
                v-model="options"
            />
            <label for="stock">Stock</label>
        </div>
    </div>
</template>
<script>
import Checkbox from "primevue/checkbox"

export default {
    components: {
        Checkbox,
    },

    data() {
        return {
            options: []
        }
    },

    watch: {
        options(newValue, oldValue) {
            this.returnOptions();
        }
    },

    methods: {
        returnOptions() {
            this.$emit("returnOptions", this.options);
        }
    },
};
</script>
<style lang="">
</style>